.switchWrapper {
  display: flex;
  align-items: center;
}

.switchLabel {
  margin-right: 1rem;
}

.switchRail {
  border: 1px solid $white;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
}

.switchInput {
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: 24px;
  width: 42px;

  &:before {
    background: $white;
    border-radius: 9999px;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    height: 18px;
    width: 18px;
    transition: $transition;
  }

  &:checked:before {
    transform: translateX(18px) translateY(-50%);
  }
}
