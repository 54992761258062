/* general form styles */

input,
textarea {
  border: 3px solid $blue-1;
  border-radius: 0;
  box-sizing: border-box;
  display: block;
  font-family: $sans;
  font-size: 0.95rem;
  line-height: 1.375;
  outline: 0;
  padding: 0.625rem;
  transition: $transition;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    border-color: $blue-2;
  }

  @media (--md) {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }
}

textarea {
  height: 150px;

  @media (--md) {
    height: 200px;
  }
}

.helper-box {
  background-image: linear-gradient(45deg, $purple-1 0%, $blue-2 75%);
  bottom: 0;
  box-sizing: border-box;
  color: $white;
  left: 0;
  line-height: 1.375;
  padding: 0.75rem 0.875rem;
  position: absolute;
  transition: 0.5s transform ease-in-out;
  transform: translateY(100%);
  width: 100%;

  @media (--md) {
    padding: 0.75rem 1rem;
  }

  &.show {
    transform: translateY(0);
  }
}

.helper {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.4;
}

.helper-item {
  letter-spacing: 0.375px;

  strong {
    font-weight: 600;
  }

  @media (--sm) {
    display: inline;

    &:not(:last-of-type) {
      margin-right: 2.5rem;
    }
  }

  @media (--lg) {
    &:not(:last-of-type) {
      margin-right: 3.5rem;
    }
  }
}

.error {
  border-color: $red-2;
  background-color: $pink;
  color: $black;
}

/* select element */

.options-dropdown-wrapper {
  background: $blue-1;
  border-radius: 5px;
  position: relative;
  z-index: 0;
}

.options-dropdown {
  background: transparent;
  border: 0;
  color: $white;
  font-family: $heading;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  width: 100%;
  -webkit-appearance: none;
}

.options-dropdown-arrow {
  top: 50%;
  transform: translateY(-50%);
  color: $white;
  position: absolute;
  right: 10px;
  height: 1.5rem;
  width: 1.5rem;
  z-index: -1;
}

/* specific elements */

.custom-tags-footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.375rem 0 0;
}

textarea.results {
  border: none;
  width: 100%;
}

.tag-count {
  @media (--md) {
    display: flex;
    align-items: center;
  }
}

.tag-count-label {
  display: block;
  font-family: $heading;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;

  @media (--md) {
    margin-bottom: 0;
  }
}

.tag-count-input {
  margin-left: 1.5rem;
  padding: 0.5rem;
  width: 75px;
}

/* form layout */

.custom-tags-holder {
  overflow: hidden;
  position: relative;
}

.form-footer {
  margin: 1.75rem 0;

  @media (--md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2.5rem 0;
  }
}

.help-trigger {
  @extend .btn-base;
  color: $blue-2;
  display: flex;
  align-items: center;

  &:hover {
    color: $blue-1;
  }
}

.help-trigger-text {
  font-family: $sans;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 1.3;
}

.help-trigger-icon {
  color: inherit;
  margin-left: 0.25rem;
  width: 0.875rem;
}
