.popover {
  background: color(white a(90%));
  border-radius: 5px;
  border: 2px solid $purple-1;
  font-family: $sans;
  font-size: 0.875rem;
  min-width: 250px;
  padding: 1rem 1.375rem 1.25rem;
  outline: 0;

  input,
  textarea {
    border-width: 2px;
    font-size: 0.9rem;
    padding: 0.375rem 0.5rem;
  }
}

.feedback-form-field {
  margin: 0 0 1rem;

  label {
    display: block;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0 0 0.25rem;
  }

  textarea {
    height: 150px;
    padding: 0.5rem 0.625rem;
  }
}

.btn--feedback {
  background: $purple-1;
  font-family: $sans;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin: 0.5rem 0.75rem 0.75rem;
  position: fixed;
  right: 0;
  bottom: 0;

  &:hover {
    background-color: $purple-3;
  }

  svg {
    position: relative;
    top: 2px;
    margin-left: 5px;
  }
}

.btn--feedback-submit {
  font-family: $sans;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin: 0.25rem 0 0;
  width: 100%;
}
