.modal-overlay {
  background: color(#12192b a(35%));
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  &[data-enter] {
    opacity: 1;
  }
}

.modal {
  background: color(white a(90%));
  border-radius: 5px;
  font-family: $sans;
  font-size: 0.9rem;
  max-width: 550px;
  padding: 1rem 1.375rem 1.25rem;
  outline: 0;

  @media (--md) {
    font-size: 1rem;
  }
}

.modal-heading {
  font-family: $heading;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.375px;
  margin: 0 0 0.5rem;
}

.modal-list {
  line-height: 1.4;
  margin-top: 1rem;

  li:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.modal-close {
  @extend .btn-base;
  border: 1px solid $blue-2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: $sans;
  font-size: 0.75rem;
  color: $blue-2;
  padding: 0.25rem 0.5rem;
  position: relative;
  letter-spacing: 0.25px;
  margin-bottom: -0.25rem;
  left: 0.25rem;

  &:hover {
    background-color: $blue-1;
    color: $white;
  }
}

.modal-close-icon {
  margin-left: 0.375rem;
}
