[data-theme='dark'] {
  main {
    background: $purple-5;
    color: $soft-white;
  }

  .section {
    input,
    textarea {
      background: $blue-2;
      color: $soft-white;

      &::placeholder {
        color: $soft-white;
      }
    }
  }

  .helper-box {
    background-image: linear-gradient(45deg, $purple-1 0%, $blue-3 75%);
  }

  .btn--submit {
    background: #bf4b4b;
  }

  .results-wrapper {
    border-color: $purple-1;
  }

  .help-trigger {
    color: $soft-white;
  }

  /* Modal */

  &.modal-overlay {
    background: color(white a(8%));
  }

  .modal {
    background: $purple-5;
    color: $softer-white;
  }

  .modal-close {
    border-color: $soft-white;
    color: $soft-white;

    &:hover {
      background-color: $purple-3;
    }
  }
}
