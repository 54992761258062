.header {
  background: $blue-2;
  color: $white;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.headerText {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.4;
}

.themeSwitch {
  position: relative;
  left: 4px;

  @media (--md) {
    left: 0;
  }

  .switchLabel {
    margin-right: 0.5rem;

    @media (--md) {
      margin-right: 0.75rem;
    }
  }

  .switchRail {
    background: color(black a(25%));

    &.switchOn {
      background: $purple-5;
    }
  }
}

.tip a {
  color: inherit;
}

.tip.compact .btn {
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  letter-spacing: inherit;
  padding: 0.25rem 0.625rem;

  @media (--md) {
    font-size: 1.075rem;
  }

  img {
    margin-right: 5px;
  }
}
