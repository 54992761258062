.suggestions {
  margin: 0 -0.2rem;
}

.results-wrapper {
  border: 3px solid $gold-2;
  margin: 0 0 0.375rem;
  position: relative;

  @media (--md) {
    &:hover .copy-results {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.copy-results {
  border: 0;
  cursor: pointer;
  font-family: $heading;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.625px;
  margin: 0;
  position: relative;
  transform: none;
  transition: $transition;

  @media (--md) {
    border-radius: 5px;
    bottom: 0;
    left: auto;
    margin: 0.5rem;
    min-width: 140px;
    font-size: 0.875rem;
    opacity: 0;
    padding: 0.375rem 0.625rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: auto;
  }
}
