.footer {
  background: $purple-4;
  color: $white;
  padding-bottom: 3rem;

  &:before {
    background: $red-1;
    background: linear-gradient(45deg, $red-1 0%, $purple-1 49%, $blue-1 99%);
    content: '';
    display: block;
    height: 12px;
    width: 100%;
  }

  @media (--md) {
    padding-bottom: 0;
  }
}

.container--footer {
  @media (--md) {
    display: flex;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}

.footer-about {
  font-size: 1.0625rem;
  line-height: 1.625;
  padding-bottom: 3rem;

  @media (--md) {
    padding-bottom: 0;
    padding-right: 10%;
    width: 60%;
  }

  p {
    @media (--md) {
      max-width: 510px;
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    a {
      color: $gold-2;
      text-decoration: underline;

      &:hover {
        color: $gold-1;
      }
    }
  }
}

.footer-follow {
  @media (--md) {
    width: 35%;
  }

  h3 {
    font-family: $heading;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }
}

.btn--footer {
  /* background: #ba2f2f; */
  background: #983d4d;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0.625rem 0;
  padding: 0.5rem 0.75rem;
  width: 100%;
  text-align: center;

  &:hover {
    /* background: $red-1; */
  }

  img {
    margin-right: 8px;
  }
}
