.banner {
  background: $blue-1;
  color: $white;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &:after {
    background-image: url('../assets/hashtag.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    opacity: 0.65;
    transform: rotate(10deg) translateZ(0);
    position: absolute;
    right: -100px;
    top: -85px;
    height: 670px;
    width: 670px;
    z-index: -1;

    @media (--md) {
      opacity: 0.75;
      height: 500px;
      width: 500px;
      right: -50px;
      top: -70px;
    }
  }
}

.banner-title {
  font-family: $heading;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.375;

  &--small {
    display: block;
    font-size: 1.25rem;
  }

  @media (--md) {
    &--small,
    &--large {
      display: inline;
      font-size: 3rem;
    }
  }
}

.banner-description {
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.125px;
  line-height: 1.5;
  margin: 1rem 0 0.75rem;
  max-width: 750px;

  @media (--md) {
    font-size: 1.175rem;
  }

  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.letter {
  animation-name: wave;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  display: inline-block;
  line-height: 1;
  min-width: 9px;
  transition: $transition;

  @media (--md) {
    min-width: 12px;
  }
}

@for $i from 0 to 14 {
  .letter-$(i) {
    animation-delay: calc(0.04s * $(i));
  }
}
