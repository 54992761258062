.btn-base {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  transition: $transition;
}

.btn--suggestion {
  background-image: linear-gradient(45deg, $red-1 0%, $purple-1 30%, $blue-1 75%);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1.25px;
  margin: 0.2rem;
  padding: 0.5rem 0.875rem;

  &:hover {
    background-image: linear-gradient(45deg, $red-1 0%, $purple-2 20%, $blue-2 75%);
  }
}

.btn--submit {
  background: $red-1;
  font-size: 1.5rem;
  letter-spacing: 1px;
  margin: 1.5rem 0 0;
  position: relative;
  width: 100%;

  &:hover {
    animation-name: shake;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    background: $purple-1;
  }

  &:focus {
    animation-name: button-push;
    animation-duration: 0.25s;
    animation-fill-mode: both;
  }

  @media (--md) {
    margin: 0;
    padding: 0.625rem 1.625rem;
    width: auto;
  }
}

.kofi {
  background: #e44c4c !important;
}
