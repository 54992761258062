body {
  background: $purple-4;
  font-family: 'inter', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

main {
  background: #fbf6fd;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

ol {
  list-style: decimal;
  line-height: 1.625;
  padding-left: 1.25rem;
}

a {
  transition: $transition;
}

.md-up {
  display: none;

  @media (--md) {
    display: block;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.container {
  max-width: 900px;
  margin: auto;
  padding: 2.5rem 25px;

  @media (--md) {
    padding: 3rem 70px;
  }

  @media (--xl) {
    padding: 3rem 25px;
  }
}

.section:not(:last-of-type) {
  margin-bottom: 2rem;

  @media (--md) {
    margin-bottom: 3rem;
  }
}

.section-heading {
  font-family: $heading;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.25;
  margin-bottom: 0.75rem;

  @media (--md) {
    font-size: 1.5rem;
  }
}

.btn {
  background: $blue-1;
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $heading;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.75px;
  opacity: 1;
  padding: 0.625rem 0.75rem;
  text-decoration: none;
  transition: $transition;
}
