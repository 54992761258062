@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  25%,
  75% {
    transform: translate3d(-5px, 0, 0);
  }

  50% {
    transform: translate3d(5px, 0, 0);
  }
}

@keyframes button-push {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 3px, 0);
  }
}

@keyframes wave {
  from,
  10% {
    transform: translate3d(0, 0, 0);
  }

  5% {
    transform: translate3d(0, -10px, 0);
  }
}
